import { ChakraProvider } from '@chakra-ui/react'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'
import React from 'react'
import {
  ErrorBoundary,
  IPagePropsPendingJobs,
  KachProvider,
} from 'kach-components'
import { authAtom } from 'atoms/auth.atom'
import { jobsAtom } from 'atoms/jobs.atom'
import { notificationsAtom } from 'atoms/notifications.atom'
import { es } from 'date-fns/locale'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import { Provider, useAtom, useSetAtom } from 'jotai'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Link from 'next/link'
import NextNprogress from 'nextjs-progressbar'
import { useEffect, useState } from 'react'
import {
  QueryClient,
  QueryClientProvider,
  defaultContext,
} from '@tanstack/react-query'
import { IPagePropsAuth } from 'ssr/auth'
import { IPagePropsNotifications } from 'ssr/fetch-notifications'
import { customTheme } from 'styles/theme'
import { GoogleTagManager } from '@next/third-parties/google'

export const GLOBAL_SCOPE_JOTAI = 'global'

setDefaultOptions({ locale: es })

// content
const AtomsRefreshers: React.FC<
  React.PropsWithChildren<{
    pageProps: IPagePropsAuth & IPagePropsNotifications & IPagePropsPendingJobs
  }>
> = (props) => {
  const setAuth = useSetAtom(authAtom, GLOBAL_SCOPE_JOTAI)
  const setNotifications = useSetAtom(notificationsAtom, GLOBAL_SCOPE_JOTAI)
  const setJobs = useSetAtom(jobsAtom, GLOBAL_SCOPE_JOTAI)

  useEffect(() => {
    setAuth(props.pageProps?.auth)
    setNotifications(props.pageProps?.notifications)
    setJobs({ jobs: props.pageProps?.jobs })
  }, [props.pageProps])

  return <>{props.children}</>
}

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
)

export default function App({
  Component,
  pageProps,
}: AppProps<IPagePropsAuth & IPagePropsNotifications & IPagePropsPendingJobs>) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchIntervalInBackground: false,
          },
        },
      }),
  )

  const [showDevtools, setShowDevtools] = React.useState(false)

  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  React.useEffect(() => {
    if (pageProps.auth && window.dataLayer) {
      window.dataLayer?.push({
        event: 'user',
        id: pageProps.auth.id,
        email: pageProps.auth.email,
        name: `${pageProps.auth.firstName} ${pageProps.auth.lastName}`,
        company: pageProps.auth.company.name,
        companyId: pageProps.auth.company.id,
      })
    }
  }, [])

  return (
    <ChakraProvider theme={customTheme}>
      <ErrorBoundary Navigator={Link}>
        <Provider
          scope={GLOBAL_SCOPE_JOTAI}
          initialValues={[
            [authAtom, pageProps?.auth],
            [notificationsAtom, pageProps?.notifications],
            [jobsAtom, { jobs: pageProps?.jobs }],
          ]}
        >
          <AtomsRefreshers pageProps={pageProps}>
            <KachProvider
              defaultContext={defaultContext}
              jobs={pageProps?.jobs || []}
              auth={pageProps?.auth}
            >
              <QueryClientProvider
                context={defaultContext}
                client={queryClient}
              >
                <NextNprogress
                  options={{
                    showSpinner: false,
                  }}
                  color={'#475465'}
                />
                <Head>
                  <meta charSet='utf-8' />
                  <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
                  <meta
                    name='viewport'
                    content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
                  />
                  <meta name='description' content='Kach Insurer' />

                  <link rel='icon' href='/kach-192x192.png' />

                  {/* Hotjar Tracking Code for Kach Insurer */}
                  <script
                    dangerouslySetInnerHTML={{
                      __html: `
                      (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:3296959,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `,
                    }}
                  />
                </Head>
                <Component {...pageProps} />
                <GoogleTagManager
                  gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}
                />

                {showDevtools && (
                  <React.Suspense fallback={null}>
                    <ReactQueryDevtoolsProduction />
                  </React.Suspense>
                )}
              </QueryClientProvider>
            </KachProvider>
          </AtomsRefreshers>
        </Provider>
      </ErrorBoundary>
    </ChakraProvider>
  )
}
